<template>
  <div class="list">
    <van-popup
      v-model="show"
      round
      position="bottom"
      :close-on-click-overlay="false"
    >
      <van-cascader
        v-model="cascaderValue"
        :closeable="false"
        title="请选择疫苗接种点"
        :options="options"
        @finish="onFinish"
        :field-names="obj"
      />
    </van-popup>
    <!-- 选择社区 -->
    <div class="community" v-if="showoverlay">
      <div class="shade"></div>
      <div class="wrapper">
        <van-radio-group
          v-model="radio"
          class="radioGroup"
          direction="horizontal"
          @change="changeCommunity"
        >
          <van-radio name="1">选择社区</van-radio>
          <van-radio name="2">填写社区</van-radio>
        </van-radio-group>
        <div class="detail">
          <van-field
            v-if="radio == '2'"
            type="textarea"
            v-model="value"
            class="input"
            placeholder="请填写社区名称"
          />
          <van-radio-group v-else v-model="radio1"  @change="changeSelectList" >
            <van-radio
              :name="item.id"
              v-for="item in selectList"
              :key="item.id"
              >{{ item.name }}</van-radio
            >
          </van-radio-group>
        </div>
        <div class="btn">
          <p @click="showoverlay = false">取消</p>
          <p @click="okBtn">确定</p>
        </div>
      </div>
    </div>
    <!-- <van-overlay :show="showoverlay" @click="showoverlay = false">
      <div class="wrapper" @click.stop>
        <van-radio-group
          v-model="radio"
          class="radioGroup"
          direction="horizontal"
          @change="changeCommunity"
        >
          <van-radio name="1">选择社区</van-radio>
          <van-radio name="2">填写社区</van-radio>
        </van-radio-group>
        <div class="detail">
          <van-field
            v-if="radio == '2'"
            type="textarea"
            v-model="value"
            class="input"
            placeholder="请填写社区名称"
          />
          <van-radio-group v-else v-model="radio1">
            <van-radio
              :name="item.name"
              v-for="item in selectList"
              :key="item.id"
              >{{ item.name }}</van-radio
            >
          </van-radio-group>
        </div>
        <div class="btn">
          <p @click="showoverlay = false">取消</p>
          <p @click="okBtn">确定</p>
        </div>
      </div>
    </van-overlay> -->
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { getJsapiTicket, cityList } from "../utils/api";
export default {
  components: {},
  data() {
    return {
      cascaderValue: "",
      show: true,
      options: [],
      jsapiTicket: {},
      obj: { text: "name", value: "id", children: "sites" },
      showoverlay: false, //社区弹窗
      radio: "1", //选择社区、填写社区
      value: "", //输入框
      radio1: "", //选择社区
      radio1Value:"",//社区值
      selectList: [], //社区列表
      cityName:'',//选中的城市名称
      vaccinationSiteId:"" ,//选中的省id
      vaccinationSiteCityId:"", //选中的市id
      vaccinationSiteAreaId:"" ,//选中的区id

      channel:""//渠道
    };
  },
  async mounted() {
    this.$toast.loading({
      message: "加载中...",
      forbidClick: true,
      duration: 0,
    });
    sessionStorage.setItem("productId", this.$route.query.id);

    // 如果参数上拼接有渠道号
    if(this.$route.query.channel!=undefined){
      this.channel=this.$route.query.channel
    }

    await this.cityList();
    this.$toast.clear();
    // await this.getJsapiTicket();
    // await this.getAuth();
    // await this.getLocation();
  },
  methods: {
    //
    okBtn() {
      if (this.radio == "1") {
        console.log(1)
        if (this.radio1 == "") {
           console.log(2)
          this.$toast("请选择社区");
        } else {
          this.cityName = `${this.cityName}-${this.radio1Value}`;
          sessionStorage.setItem('cityName',this.cityName);
          sessionStorage.setItem('vaccinationSiteId',this.vaccinationSiteId);
          sessionStorage.setItem('vaccinationSiteCityId',this.vaccinationSiteCityId);
          sessionStorage.setItem('vaccinationSiteAreaId',this.vaccinationSiteAreaId);
          sessionStorage.setItem('vaccinationSiteCommunityId',this.radio1);
          sessionStorage.setItem('vaccinationSiteCommunityName','');
          this.$router.push({
            path: "/index",
          });
        }
      } else {
        if (this.value == "") {
          this.$toast("请填写社区");
        } else {
          this.cityName = `${this.cityName}-${this.value}`;
          sessionStorage.setItem('cityName',this.cityName);
          sessionStorage.setItem('vaccinationSiteId',this.vaccinationSiteId);
          sessionStorage.setItem('vaccinationSiteCityId',this.vaccinationSiteCityId);
          sessionStorage.setItem('vaccinationSiteAreaId',this.vaccinationSiteAreaId);
          sessionStorage.setItem('vaccinationSiteCommunityId','');
          sessionStorage.setItem('vaccinationSiteCommunityName',this.value);
          this.$router.push({
            path: "/index",
          });
        }
      }
      
    },
    //选择社区方式
    changeCommunity() {
      this.value = "";
      this.radio1 = "";
    },
    // 选择社区
    changeSelectList(e){
      this.selectList.map(item=>{
        if(item.id==e){
           this.radio1Value=item.name
        }
      })
    },
    //注入wx配置
    getAuth() {
      wx.config({
        debug: true,
        appId: this.jsapiTicket.appId,
        timestamp: this.jsapiTicket.timestamp,
        nonceStr: this.jsapiTicket.nonceStr,
        signature: this.jsapiTicket.signature,
        jsApiList: ["checkJsApi", "getLocation"],
      });
    },
    //获取定位
    getLocation() {
      wx.ready(function () {
        //   wx.checkJsApi({
        //   jsApiList: ["getLocation"], // 需要检测的JS接口列表，所有JS接口列表见附录2,
        //   success: function (res) {
        //     console.log(res);
        //     // 以键值对的形式返回，可用的api值true，不可用为false
        //     // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
        //   },
        // });
        wx.getLocation({
          type: "wgs84",
          success: function (res1) {
            console.log(res1);
          },
          fail: function (err) {
            console.log(err);
          },
        });
      });
    },
    //获取定位jsapi
    async getJsapiTicket() {
      let url = encodeURIComponent(window.location.href.split("#")[0]);
      let params = {
        channelId: "wx150dd25745296b4e",
        url: url,
      };
      await getJsapiTicket(params).then((res) => {
        this.jsapiTicket = res.result;
      });
    },
    //城市列表
    cityList() {
      let params = {
        lng: "116.087158",
        lat: "40.550522",
      };
      // 如果有渠道号
      if(this.channel!=""){
        params.channel=this.channel
      }

      cityList(params).then((res) => {
        if (res.success) {
          res.result.list.map((item) => {
            if (item.sites.length == 0) {
              delete item.sites;
            } else {
              item.sites.map((item1) => {
                if (item1.sites.length == 0) {
                  delete item1.sites;
                } else {
                  item1.sites.map((item2) => {
                    delete item2.sites;
                    // if (item2.sites.length == 0) {
                    //   delete item2.sites;
                    // } else {
                    //   item2.sites.map((item3) => {
                    //     if (item3.sites.length == 0) {
                    //       delete item3.sites;
                    //     }
                    //   });
                    // }
                  });
                }
              });
            }
          });
          this.options = res.result.list;
        }
      });
    },
    //选择完成
    onFinish({ selectedOptions }) {
      this.vaccinationSiteId=selectedOptions[0].id
      this.vaccinationSiteCityId=selectedOptions[1].id
      this.vaccinationSiteAreaId=selectedOptions[2].id
      console.log(selectedOptions,"省id")
      this.cityName = selectedOptions[selectedOptions.length - 1].mergeName;
      console.log(this.cityName)
      let params = {
        lng: "116.087158",
        lat: "40.550522",
      };
        // 如果有渠道号
      if(this.channel!=""){
        params.channel=this.channel
      }
      cityList(params).then((res) => {
        res.result.list.map((item) => {
          item.sites.map((item1) => {
            item1.sites.map((item2) => {
              if (item2.mergeName == this.cityName) {
                this.selectList = item2.sites;
              }
            });
          });
        });
      });

      this.showoverlay = true;
      //   "cityName",
      //   selectedOptions[selectedOptions.length - 1].mergeName
      // );
      // this.$router.push({
      //   path: "/index",
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
.community{
  
  .shade{
    width: 100%;
    height: 100vh;
    background: #000;
    opacity: 0.5;
    position: fixed;
    z-index: 2003 !important;
  }
}
.input {
  width: 100%;
  height: 80px;
  border: 1px solid #eaeaea;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
}
// /deep/ .van-radio-group {
  // margin-left: 15px;
// }
/deep/ .van-radio__icon--checked .van-icon {
  background-color: $primary-color;
  border-color: $primary-color;
}
/deep/ .van-radio__label {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
}
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border-top: 1px solid #eaeaea;
  p {
    width: 50%;
    font-size: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  p:first-child {
    color: #333333;
    border-right: 1px solid #eaeaea;
  }
  p:last-child {
    color: $primary-color;
  }
}
.radioGroup {
  padding: 20px 20px 10px;
}
.wrapper {
  // padding-top: 30px;
  // padding-left: 20px;
  position: fixed;
  z-index: 2004;
  width: 300px;
  height: 250px;
  left: 50%;
  top: 50%;
  margin-left: -150px;
  margin-top: -125px;
  background: #fff;
  border-radius: 8px;
}
.detail {
  width: 100%;
  padding: 0 20px;
  height: 150px;
  padding-top: 10px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: auto;
  /deep/ .van-radio {
    // padding-bottom: 10px;
    height: 40px;
    // font-size: 14px;
    // line-height: 22px;
  }
  // background: red;
}
/deep/ .van-overlay {
  z-index: 99;
}
/deep/ .van-tabs__line {
  background-color: $primary-color !important;
}
/deep/ .van-cascader__option--selected {
  color: $primary-color !important;
}
</style>